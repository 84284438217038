import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import HomeTopLayout from "../components/HomeTopLayout";
import AboutTop from "../../assets/img/abouttop.png";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import Language from "../common/Language";
import ReactPlayer from "react-player";
import VideoThumb2 from "../../assets/img/video-thumb-2.png";

const styles = (theme) => ({
  background: {
    background: "rgba(255,255,255,1)", // Average color of the background image.
    backgroundPosition: "center",
    position: "relative",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    zIndex: -2,
    minWidth: "1280px",
  },
  image: {
    display: "flex",
    flexDirection: "column",
    width: "640px",
    height: "880px",
    position: "relative",
  },
  topVideo: {
    position: "absolute",
    top: "160px",
    left: "-30px",
  },
  container_left: {
    marginTop: "147px",
    marginLeft: "150px",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    [theme.breakpoints.up("sm")]: {
      height: "80vh",
      minHeight: 500,
      maxHeight: 1300,
    },
  },
  left_title: {
    fontSize: "66px",
    fontWeight: 500,
    width: "532px",
    // fontFamily: 'PingFangSC-Medium,PingFang SC;',
    lineHeight: "92px",
    color: "rgba(11,16,60,1)",
  },
  left_subtitle: {
    marginTop: "60px",
    fontSize: "16px",
    fontWeight: 500,
    width: "394px",
    // fontFamily: 'PingFangSC-Medium,PingFang SC;',
    lineHeight: "22px",
    color: "rgba(90,90,90,1)",
  },
  left_subtitle1: {
    marginTop: "40px",
    fontSize: "16px",
    fontWeight: 500,
    width: "394px",
    // fontFamily: 'PingFangSC-Medium,PingFang SC;',
    lineHeight: "22px",
    color: "rgba(90,90,90,1)",
  },
});

function ProductAbout(props) {
  const { classes } = props;
  const lang = Language.CurLanguage();

  const [playVideo, setPlayVideo] = React.useState(false);

  const handleVideoClick = () => {
    setPlayVideo(true);
  };

  return (
    <HomeTopLayout backgroundClassName={classes.background}>
      <div className={clsx(classes.background)} />
      <Grid
        container
        justify="flex-start"
        wrap="nowrap"
        spacing={3}
        className={classes.background}
      >
        <Grid item md={6}>
          <div className={classes.container_left}>
            <h1>
              <Typography className={classes.left_title}>
                {Language.About.Mission.Title[lang]}
              </Typography>
            </h1>

            <Typography className={classes.left_subtitle}>
              {Language.About.Mission.Content1[lang]}
              {Language.About.Mission.Content2[lang]}
            </Typography>
            <Typography className={classes.left_subtitle1}>
              {Language.About.Mission.Content3[lang]}
              {Language.About.Mission.Content4[lang]}
            </Typography>
          </div>
        </Grid>
        <Grid item md={6}>
          <div className={classes.image} onClick={handleVideoClick}>
            <ReactPlayer
              light={VideoThumb2}
              className={classes.topVideo}
              url={
                lang === "Spanish"
                  ? "https://media.riochain.io/rio-defi-intro-spanish.mov"
                  : "https://www.youtube.com/watch?v=j_xS3U4l_rQ"
              }
              playing={playVideo}
              controls
            />
            {!playVideo && <img src={AboutTop} alt="" />}
          </div>
        </Grid>
      </Grid>
    </HomeTopLayout>
  );
}

ProductAbout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductAbout);
